import React from 'react'

import {
  FormGroup,
  Input,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import { FormGroupExample } from '../../examples/react/FormGroupExample'
import { LabelExample } from '../../examples/react/LabelExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FormGroup"
    components={[{ component: FormGroup }]}
    status={[{ type: 'accessible', version: '14.3.0' }]}
  >
    <Section title="Etäisyyden asettelu">
      <Paragraph>
        Lomakekentän otsikosta ja lomakekentästä koostuva pari tulee olla{' '}
        <Code>FormGroup</Code>-komponentin sisällä, jolloin lomakkeen elementit
        saavat etäisyyttä toisiinsa.
      </Paragraph>
      <Paragraph>
        Tarpeen vaatiessa lomakkeen viimeiseltä <Code>FormGroup</Code>
        :lta tulee poistaa marginaali (<Code>noMargin</Code>).
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Input
              label="Label"
              placeholder="Placeholder"
              required
            />
          </FormGroup>
          <FormGroup noMargin>
            <Input
              error="Error"
              help="Help"
              label="Label"
              placeholder="Placeholder"
              required
              subLabel="Sub Label"
            />
          </FormGroup>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <div className="form-group">
            <LabelHTMLExample htmlFor="label-html-example-1">
              Label
            </LabelHTMLExample>
            <input
              className="form-control"
              id="label-html-example-1"
              placeholder="Placeholder"
              type="text"
            />
          </div>
          <div className="form-group mb-0">
            <LabelHTMLExample
              htmlFor="form-group-html-example-2"
              required
              subLabel="Sub Label"
              subLabelId="form-group-html-example-2-sublabel"
            >
              Label
            </LabelHTMLExample>
            <input
              aria-describedby="form-group-html-example-2-sublabel"
              className="form-control"
              id="form-group-html-example-2"
              placeholder="Placeholder"
              required
              type="text"
            />
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Ryhmittely yhden otsikon alle">
      <Paragraph>
        <Code>FormGroup</Code>-komponentilla voi ryhmitellä lomake-elementtejä
        yhden otsikon alle.
      </Paragraph>

      <Playground
        example={FormGroupExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        example={LabelExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>
        <Code>FormGroup</Code>-komponenttia on mahdollista käyttää kahteen
        tarkoitukseen:
      </Paragraph>
      <List variant="unordered">
        <ListItem>lomakkeen kenttien visuaaliseen asetteluun</ListItem>
        <ListItem>
          semanttisen ryhmän luomiseen. Semanttista ryhmää varten{' '}
          <Code>FormGroup</Code>ille tulee määritellä ryhmän nimi{' '}
          <Code>ariaLabelledby</Code>-propilla. Se viittaa id:llä elementtiin,
          jonka sisältämä teksti luetaan ryhmään saapuessa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
