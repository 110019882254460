import React from 'react'

import {
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Stack
} from '@te-digi/styleguide'

const LabelExample = () => (
  <FormGroup
    ariaLabelledby="input-group-prepend-example-label"
    noMargin
  >
    <Label
      as="p"
      id="input-group-prepend-example-label"
    >
      Kieliversioitu kenttä
    </Label>
    <Stack gap="md">
      <Input
        lang="fi"
        addonPrepend={<InputGroupText ariaLabel="Suomeksi">FI</InputGroupText>}
      />
      <Input
        lang="en"
        addonPrepend={
          <InputGroupText ariaLabel="In English">EN</InputGroupText>
        }
      />
    </Stack>
  </FormGroup>
)

export { LabelExample }
