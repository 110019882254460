import React from 'react'

import { FormGroup, Heading, Input } from '@te-digi/styleguide'

const FormGroupExample = () => (
  <FormGroup
    ariaLabelledby="group-label"
    noMargin
  >
    <Heading
      id="group-label"
      level={3}
    >
      Ryhmän yhteinen otsikko
    </Heading>
    <FormGroup>
      <Input label="Label 1" />
    </FormGroup>
    <FormGroup noMargin>
      <Input label="Label 2" />
    </FormGroup>
  </FormGroup>
)

export { FormGroupExample }
